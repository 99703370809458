import React, {useState} from 'react';
import {ongoingProjects} from "../../content/projects";

const ClothingEnhancedHAR = () => {
    const project = ongoingProjects.filter(x => x.id === 'clothing-enhanced-har')[0];

    return (
        <div className="sm:px-8 mt-16 sm:mt-32">
            <div className="mx-auto max-w-7xl lg:px-8">
                <div className="relative px-4 sm:px-8 lg:px-12">
                    <div className="mx-auto max-w-2xl lg:max-w-5xl">
                        <header className="max-w-2xl">
                            <h1 className="text-4xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl">
                                <span
                                    className='bg-gradient-to-r bg-clip-text text-transparent from-violet-500 via-fuchsia-500 to-violet-500 animate-text'>
                                    {project.name}
                                </span>
                            </h1>
                        </header>

                        <div className={"flex flex-col gap-8 justify-start"}>
                            <div className="mt-8 sm:mt-10 flex flex-col gap-4">
                                <h2 className="text-3xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-3xl">
                                    <span>
                                        Technical Approach
                                    </span>
                                </h2>
                                <div className="space-y-6 flex flex-col">
                                    {project.technicalApproach.map((x, i) => {
                                        return (
                                            <div>
                                                {x}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className={"flex flex-col gap-8 justify-start"}>
                            <div className="mt-8 sm:mt-10 flex flex-col gap-4">
                                <h2 className="text-3xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-3xl">
                                    <span>
                                        Clothed Data
                                    </span>
                                </h2>
                                <div className={"grid grid-cols-2 gap-8"}>
                                    <div className="space-y-6 flex flex-col">
                                        {project.clothedData.content.map((x, i) => {
                                            return (
                                                <div className="items-center">
                                                    {x}
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className={"flex flex-col gap-6 col-span-1 h-full justify-center"}>
                                        <img src={project.clothedData.src} alt={"Clothed Data"} className={"w-full h-auto"}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"flex flex-col gap-8 justify-start"}>
                            <div className="mt-8 sm:mt-10 flex flex-col gap-4">
                                <h2 className="text-3xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-3xl">
                                    <span>
                                        Unclothed Data
                                    </span>
                                </h2>
                                <div className={"grid grid-cols-2 gap-8"}>
                                    <div className={"flex flex-col gap-6 col-span-1 h-full justify-center"}>
                                        <img src={project.unclothedData.src} alt={"Unclothed Data"}
                                             className={"w-full h-auto"}/>
                                    </div>
                                    <div className="space-y-6 flex flex-col">
                                        {project.unclothedData.content.map((x, i) => {
                                            return (
                                                <div className="items-center">
                                                    {x}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*<div className={"flex flex-col gap-8 justify-start"}>*/}
                        {/*    <div className="mt-8 sm:mt-10 flex flex-col gap-4">*/}
                        {/*        <h2 className="text-3xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-3xl">*/}
                        {/*            <span>*/}
                        {/*                Creative Expression*/}
                        {/*            </span>*/}
                        {/*        </h2>*/}
                        {/*        <div className={"grid grid-cols-2 gap-8"}>*/}
                        {/*            <div className={"flex flex-col gap-6 col-span-1 h-full justify-center"}>*/}
                        {/*                <img src={project.creativeExpression.src} alt={"SDF2"}*/}
                        {/*                     className={"w-full h-auto"}/>*/}
                        {/*            </div>*/}
                        {/*            <div className="space-y-6 flex flex-col">*/}
                        {/*                {project.creativeExpression.content.map((x, i) => {*/}
                        {/*                    return (*/}
                        {/*                        <div>*/}
                        {/*                            {x}*/}
                        {/*                        </div>*/}
                        {/*                    )*/}
                        {/*                })}*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClothingEnhancedHAR;