import React, {useState} from 'react';
import {ongoingProjects} from "../../content/projects";

const SDFPrimitives = () => {
    const project = ongoingProjects.filter(x => x.id === 'sdf-primitives-and-representation')[0];

    return (
        <div className="sm:px-8 mt-16 sm:mt-32">
            <div className="mx-auto max-w-7xl lg:px-8">
                <div className="relative px-4 sm:px-8 lg:px-12">
                    <div className="mx-auto max-w-2xl lg:max-w-5xl">
                        <header className="max-w-2xl">
                            <h1 className="text-4xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl">
                                <span
                                    className='bg-gradient-to-r bg-clip-text text-transparent from-violet-500 via-fuchsia-500 to-violet-500 animate-text'>
                                    {project.name}
                                </span>
                            </h1>
                        </header>

                        <div className={"flex flex-col gap-8 justify-start"}>
                            <div className="mt-8 sm:mt-10 flex flex-col gap-4">
                                <h2 className="text-3xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-3xl">
                                    <span>
                                        Technical Explanation
                                    </span>
                                </h2>
                                <div className={"grid grid-cols-2 gap-8"}>
                                    <div className="space-y-6 flex flex-col">
                                        {project.technicalExplanation.content.map((x, i) => {
                                            return (
                                                <div className="items-center">
                                                    <h3 className="font-semibold text-lg">
                                                        {x.title}
                                                    </h3>
                                                    <div className="text-base text-zinc-600 dark:text-zinc-400 text-sm">
                                                        {x.text}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className={"flex flex-col gap-6 col-span-1 h-full justify-center"}>
                                        <img src={project.technicalExplanation.src} alt={"SDF1"} className={"w-full h-auto"}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"flex flex-col gap-8 justify-start"}>
                            <div className="mt-8 sm:mt-10 flex flex-col gap-4">
                                <h2 className="text-3xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-3xl">
                                    <span>
                                        Creative Expression
                                    </span>
                                </h2>
                                <div className={"grid grid-cols-2 gap-8"}>
                                    <div className={"flex flex-col gap-6 col-span-1 h-full justify-center"}>
                                        <img src={project.creativeExpression.src} alt={"SDF2"}
                                             className={"w-full h-auto"}/>
                                    </div>
                                    <div className="space-y-6 flex flex-col">
                                        {project.creativeExpression.content.map((x, i) => {
                                            return (
                                                <div>
                                                    {x}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SDFPrimitives;